
import React from "react"
// import { useContext, useState, useEffect, useRef, useMemo} from "react"
import {Routes, Route, Navigate} from "react-router-dom"
import BlogHeader from "./components/blog/blogHeader"
import BlogList from "./components/blog/blogList"
import BlogOpenSourceToolkit from "./components/blog/blogOpenSourceToolkit"
import BlogAISafetyReport from "./components/blog/blogAiSafetyReport"

function Blog() {

  return (

<div class="flex flex-col w-full h-screen">
    <BlogHeader />    
     <Routes path="/*">
         <Route exact path="/" element={<BlogList/>}/>
         <Route exact path="/open-source-toolkit" element={<BlogOpenSourceToolkit/>}/>
         <Route exact path="/ai-safety-report" element={<BlogAISafetyReport/>}/>
         <Route path="*" element={<Navigate to="/blog" replace />} />
     </Routes>
    
</div>  
    )
}


export default Blog;