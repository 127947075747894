import React from "react";

const navigation = [
  { name: 'Evaly', href: "/" },
  // { name: 'Features', href: '#' },
  // { name: 'Marketplace', href: '/store' },
  // { name: 'Pricing', href: '/pricing' },
  // { name: 'Company', href: '#' },
]



function LandingHeader () {
    return (


      <div class="flex flex-row justify-between items-center mr-4 py-5 bg-gray-950"  style={{paddingTop: "20px", paddingBottom:"20px"}}> 
              <div class="mx-3 px-4 flex flex-row items-center justify-between">
                    <img
                        style={{height: "3rem"}}
                        src={`../../tigers.png`}
                        alt=""
                    />
                      <h
                        class="block px-3 text-3xl whitespace-nowrap w-64 font-extrabold text-orange-500 {
                          font-family: 'Arial', sans-serif;
                      } "
                      >
                        {"TigerBytes"}
                      </h>
              </div>
              <div class="flex">
                <a class="mx-4 px-6 py-2 text-lg font-medium lg:rounded-md text-orange-500 hover:text-sky-600" href="https://getwaitlist.com/waitlist/13018" target="_blank">Beta Waitlist</a>
                <a class="mx-4 px-6 py-2 text-lg font-medium lg:rounded-md text-orange-500 hover:text-sky-600" href="https://calendly.com/tigerbytes-ai/discovery" target="_blank">Schedule Demo</a>
                {/* <a class="mx-4 px-6 py-2 text-lg font-medium lg:rounded-md text-orange-500 hover:text-sky-600" href="" >Blog</a> */}
  {/*                  <a class="px-6 py-2 text-lg font-medium lg:rounded-md text-white hover:text-sky-600" href="https://github.com/tigerlab-ai/tiger" target="_blank">GitHub</a> */}
              </div>
          </div>
    )
}

// function LandingHeader() {
//   return (
//       <div class="flex flex-row justify-between items-center py-5 bg-gray-950" style={{paddingTop: "20px", paddingBottom: "20px"}}>
//           <div class="flex-grow">
//               <div class="flex justify-start ml-4">
//                   <img
//                       style={{height: "4rem"}}
//                       src={`../../tigers.png`}
//                       alt="TigerBytes AI logo"
//                   />
//                   <h1 class="text-4xl font-bold text-orange-500 pl-3">
//                       TigerBytes AI
//                   </h1>
//               </div>
//           </div>
//           <div class="flex-grow">
//               <div class="flex justify-center space-x-4">
//                   <a class="px-6 py-2 text-lg font-medium lg:rounded-md text-orange-500 hover:text-sky-600" href="https://forms.gle/iMRuYEuJtQqt7T5XA" target="_blank">Beta waitlist</a>
//                   <a class="px-6 py-2 text-lg font-medium lg:rounded-md text-orange-500 hover:text-sky-600" href="https://calendly.com/tigerlab-ai/30min" target="_blank">Talk to us</a>
//                   <a class="px-6 py-2 text-lg font-medium lg:rounded-md text-orange-500 hover:text-sky-600" href="/blog">Blog</a>
//                   {/* Other links can be added here */}
//               </div>
//           </div>
//           <div class="flex-grow">
//               {/* This is for symmetry. If you have content to put on the right, it can go here. */}
//           </div>
//       </div>
//   )
// }

export default LandingHeader
