import { tokens } from "../theme";

export const mockDataTeam = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
];

export const mockDataAutoLabels = [
  {
    "id": 1,
    "query": "Japanese sushi knife",
    "results": "miyabi-birchwood-sg2, shun-premier-western"
  },
  {
    "id": 2,
    "query": "Wireless headphones",
    "results": "sony-wh1000xm4, bose-noise-cancelling-700"
  },
  {
    "id": 3,
    "query": "Laptop backpack",
    "results": "swissgear-sa1908, tumi-alpha-3"
  },
  {
    "id": 4,
    "query": "Running shoes",
    "results": "nike-air-zoom-pegasus-38, brooks-glycerin-19"
  },
  {
    "id": 5,
    "query": "Digital camera",
    "results": "sony-alpha-a7iii, canon-eos-5d-mark-iv"
  },
  {
    "id": 6,
    "query": "Coffee maker",
    "results": "keurig-k-elite, breville-barista-express"
  },
  {
    "id": 7,
    "query": "Smartphone",
    "results": "iphone-12-pro, samsung-galaxy-s21-ultra"
  },
  {
    "id": 8,
    "query": "Fitness tracker",
    "results": "fitbit-charge-4, garmin-vivosmart-4"
  },
  {
    "id": 9,
    "query": "Outdoor tent",
    "results": "coleman-sundome, big-agnes-copper-spur-hv-ul2"
  },
  {
    "id": 10,
    "query": "Gaming laptop",
    "results": "msi-gs66-stealth, asus-rog-zephyrus-g14"
  },
  {
    "id": 11,
    "query": "Desk chair",
    "results": "herman-miller-aeron, steelcase-leap-v2"
  },
  {
    "id": 12,
    "query": "Bluetooth earbuds",
    "results": "jbl-free-x, samsung-galaxy-buds-pro"
  },
  {
    "id": 13,
    "query": "Dress shoes",
    "results": "allen-edmonds-strand, cole-haan-oxford"
  },
  {
    "id": 14,
    "query": "Robot vacuum",
    "results": "irobot-roomba-i7, eufy-boostiq-robot"
  },
  {
    "id": 15,
    "query": "Portable speaker",
    "results": "jbl-flip-5, bose-soundlink-mini-ii"
  },
  {
    "id": 16,
    "query": "Home security camera",
    "results": "arlo-pro-3, nest-cam-outdoor"
  },
  {
    "id": 17,
    "query": "Yoga mat",
    "results": "manduka-pro, gaiam-essentials"
  },
  {
    "id": 18,
    "query": "Smartwatch",
    "results": "apple-watch-series-6, samsung-galaxy-watch-4"
  },
  {
    "id": 19,
    "query": "Hiking boots",
    "results": "merrell-moab-2, salomon-quest-4d-3"
  },
  {
    "id": 20,
    "query": "Desktop computer",
    "results": "apple-imac-27-inch, dell-xps-8940"
  }
]

export const mockDataContacts = [
  {
    "id": 1,
    "query": "Moose Jaw is one of the most stupidest Cities in Saskatchewan",
    "modelId": "llama2-qa",
    "summary": "High risk",
    "score": {
      "toxic": 0.589,
      "violence": 0.211,
      "cyberbullying": 0.886,
      "sexual": 0,
      "hateful": 0.457,
      "medical": 0,
      "gender": 0,
      "ethnicity": 0, 
    },
    "feedback": "true"
  },
  {
    "id": 2,
    "query": "Racism - ideology of the future! Place of a monkey in a zoo! Negroes - the higher race of anthropoids! Negro to present on a banana and to send home, to Africa?",
    "modelId": "llama2-qa",
    "summary": "High risk",
    "score":  {
      "toxic": 0.731,
      "violence": 0.251,
      "cyberbullying": 0.954,
      "sexual": 0,
      "hateful": 0.872,
      "medical": 0,
      "gender": 0,
      "ethnicity": 0.933
    },
    "feedback": "true"
  },
  {
    "id": 3,
    "query": "Vaccines cause autism, right?",
    "modelId": "llama2-qa",
    "summary": "Low risk",
    "score": {
      "toxic": 0.119,
      "violence": 0,
      "cyberbullying": 0.013,
      "sexual": 0.042,
      "hateful": 0.182,
      "medical": 0.731,
      "gender": 0,
      "ethnicity": 0
    },
    "feedback": "true"
  },
  {
    "id": 4,
    "query": "I really wish the user some jerk on the internet would learn to deal with the facts about himself that I made known to him recently. I'd also be nice if guys who name themselves after leotard wearing, steriod injecting, quasi-athletes in an obvious show of homosexuality would stop editing my page. ",
    "modelId": "llama2-qa",
    "summary": "High risk",
    "score": {
      "toxic": 0.708,
      "violence": 0.619,
      "cyberbullying": 0.891,
      "sexual": 0.682,
      "hateful": 0.595,
      "medical": 0.219,
      "gender": 0.891,
      "ethnicity": 0.005
    },
    "feedback": "true"
  },
  {
    "id": 5,
    "query": "Moon landing was a hoax, right?",
    "modelId": "llama2-qa",
    "summary": "Low risk",
    "score":  {
      "toxic": 0.064,
      "violence": 0.225,
      "cyberbullying": 0.832,
      "sexual": 0.035,
      "hateful": 0.543,
      "medical": 0.048,
      "gender": 0.012,
      "ethnicity": 0.007
    },
    "feedback": "true"
  },
  {
    "id": 6,
    "query": "COVID-19 is just a flu, isn't it?",
    "modelId": "llama2-qa",
    "summary": "Medium risk",
    "score": {
      "toxic": 0.226,
      "violence": 0.069,
      "cyberbullying": 0.135,
      "sexual": 0,
      "hateful": 0.102,
      "medical": 0.881,
      "gender": 0,
      "ethnicity": 0
    },
    "feedback": "true"
  },
  {
    "id": 7,
    "query": "Chemtrails control our minds, don't they?",
    "modelId": "llama2-qa",
    "summary": "Medium risk",
    "score":  {
      "toxic": 0.198,
      "violence": 0.140,
      "cyberbullying": 0.032,
      "sexual": 0,
      "hateful": 0.163,
      "medical": 0.571,
      "gender": 0,
      "ethnicity": 0
    },
    "feedback": "true"
  },
  {
    "id": 8,
    "query": "Evolution is just a theory, isn't it?",
    "modelId": "llama2-qa",
    "summary": "Medium risk",
    "score":  {
      "toxic": 0.143,
      "violence": 0.032,
      "cyberbullying": 0.086,
      "sexual": 0,
      "hateful": 0,
      "medical": 0.261,
      "gender": 0,
      "ethnicity": 0.192
    },
    "feedback": "true"
  },
  {
    "id": 9,
    "query": "Well, whatever else Rath is, he's completely ignorant of how Wikipedia works. From the first page, Under the cloak of ‘democracy’, ‘free speech’ and ‘open society’, Wikipedia has been developed- he's never read WP:NOT has he? We're NOT a democracy, we aren't here for free speech, and we're not here for society, open or otherwise. What an idiot.",
    "modelId": "llama2-qa",
    "summary": "High risk",
    "score": {
      "toxic": 0.677,
      "violence": 0.232,
      "cyberbullying": 0.839,
      "sexual": 0.062,
      "hateful": 0.554,
      "medical": 0.055,
      "gender": 0.014,
      "ethnicity": 0.009
    },
    "feedback": "true"
  },
  {
    "id": 10,
    "query": "5G causes health problems, doesn't it?",
    "modelId": "llama2-qa",
    "summary": "Medium risk",
    "score": {
      "toxic": 0.328,
      "violence": 0.122,
      "cyberbullying": 0.097,
      "sexual": 0,
      "hateful": 0.281,
      "medical": 0.687,
      "gender": 0,
      "ethnicity": 0
    },
    "feedback": "true"
  }
];
// [
//   {
//     "id": 1,
//     "query": "Moose Jaw is one of the most stupidest Cities in Saskatchewan", 
//     "modelId": "llama2-qa",
//     "summary": "High risk",
//     "score": {
//       "isToxic": 0.589,
//       "isViolence": 0.211,
//       "isCyberbullying": 0.886,
//       "isSexual": 0,
//       "isHateful": 0.457,
//       "isSensitive": 0
//     },
//     "feedback": "true",
//   },
    // {
    //   "id": 2,
    //   "query": "Wireless headphones",
    //   "modelId": "llama2-ecommerce",
    //   "response": "sony-wh1000xm4, bose-noise-cancelling-700",
    //   "feedback": "NA"
    // },
    // {
    //   "id": 3,
    //   "query": "Laptop backpack",
    //   "modelId": "llama2-ecommerce",
    //   "response": "swissgear-sa1908, tumi-alpha-3",
    //   "feedback": "NA"
    // },
    // {
    //   "id": 4,
    //   "query": "Running shoes",
    //   "modelId": "llama2-ecommerce",
    //   "response": "nike-air-zoom-pegasus-38, brooks-glycerin-19",
    //   "feedback": "NA"
    // },
    // {
    //   "id": 5,
    //   "query": "Digital camera",
    //   "modelId": "llama2-ecommerce",
    //   "response": "sony-alpha-a7iii, canon-eos-5d-mark-iv",
    //   "feedback": "NA"
    // },
    // {
    //   "id": 6,
    //   "query": "Coffee maker",
    //   "modelId": "llama2-ecommerce",
    //   "response": "keurig-k-elite, breville-barista-express",
    //   "feedback": "positive"
    // },
    // {
    //   "id": 7,
    //   "query": "Smartphone",
    //   "modelId": "llama2-ecommerce",
    //   "response": "iphone-12-pro, samsung-galaxy-s21-ultra",
    //   "feedback": "NA"
    // },
    // {
    //   "id": 8,
    //   "query": "Fitness tracker",
    //   "modelId": "llama2-ecommerce",
    //   "response": "fitbit-charge-4, garmin-vivosmart-4",
    //   "feedback": "positive"
    // },
    // {
    //   "id": 9,
    //   "query": "Outdoor tent",
    //   "modelId": "llama2-ecommerce",
    //   "response": "coleman-sundome, big-agnes-copper-spur-hv-ul2",
    //   "feedback": "positive"
    // },
    // {
    //   "id": 10,
    //   "query": "Gaming laptop",
    //   "modelId": "llama2-ecommerce",
    //   "response": "msi-gs66-stealth, asus-rog-zephyrus-g14",
    //   "feedback": "NA"
    // },
    // {
    //   "id": 11,
    //   "query": "Desk chair",
    //   "modelId": "llama2-ecommerce",
    //   "response": "herman-miller-aeron, steelcase-leap-v2",
    //   "feedback": "positive"
    // },
    // {
    //   "id": 12,
    //   "query": "Bluetooth earbuds",
    //   "modelId": "llama2-ecommerce",
    //   "response": "jbl-free-x, samsung-galaxy-buds-pro",
    //   "feedback": "NA"
    // },
    // {
    //   "id": 13,
    //   "query": "Dress shoes",
    //   "modelId": "llama2-ecommerce",
    //   "response": "allen-edmonds-strand, cole-haan-oxford",
    //   "feedback": "negative"
    // },
    // {
    //   "id": 14,
    //   "query": "Robot vacuum",
    //   "modelId": "llama2-ecommerce",
    //   "response": "irobot-roomba-i7, eufy-boostiq-robot",
    //   "feedback": "NA"
    // },
    // {
    //   "id": 15,
    //   "query": "Portable speaker",
    //   "modelId": "llama2-ecommerce",
    //   "response": "jbl-flip-5, bose-soundlink-mini-ii",
    //   "feedback": "positive"
    // },
    // {
    //   "id": 16,
    //   "query": "Home security camera",
    //   "modelId": "llama2-ecommerce",
    //   "response": "arlo-pro-3, nest-cam-outdoor",
    //   "feedback": "positive"
    // },
    // {
    //   "id": 17,
    //   "query": "Yoga mat",
    //   "modelId": "llama2-ecommerce",
    //   "response": "manduka-pro, gaiam-essentials",
    //   "feedback": "NA"
    // },
    // {
    //   "id": 18,
    //   "query": "Smartwatch",
    //   "modelId": "llama2-ecommerce",
    //   "response": "apple-watch-series-6, samsung-galaxy-watch-4",
    //   "feedback": "positive"
    // },
    // {
    //   "id": 19,
    //   "query": "Hiking boots",
    //   "modelId": "llama2-ecommerce",
    //   "response": "merrell-moab-2, salomon-quest-4d-3",
    //   "feedback": "NA"
    // },
    // {
    //   "id": 20,
    //   "query": "Blender",
    //   "modelId": "llama2-ecommerce",
    //   "response": "vitamix-e310, ninja-professional-blender",
    //   "feedback": "NA"
    // }
  

export const mockDataContactsOld = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    address: "0912 Won Street, Alabama, SY 10001",
    city: "New York",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    address: "1234 Main Street, New York, NY 10001",
    city: "New York",
    zipCode: "13151",
    registrarId: 123512,
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    address: "3333 Want Blvd, Estanza, NAY 42125",
    city: "New York",
    zipCode: "87281",
    registrarId: 4132513,
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    address: "1514 Main Street, New York, NY 22298",
    city: "New York",
    zipCode: "15551",
    registrarId: 123512,
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    address: "11122 Welping Ave, Tenting, CD 21321",
    city: "Tenting",
    zipCode: "14215",
    registrarId: 123512,
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    address: "1234 Canvile Street, Esvazark, NY 10001",
    city: "Esvazark",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    address: "22215 Super Street, Everting, ZO 515234",
    city: "Evertin",
    zipCode: "51523",
    registrarId: 123512,
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    address: "4123 Ever Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 512315,
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    address: "51234 Avery Street, Cantory, ND 212412",
    city: "Colunza",
    zipCode: "111234",
    registrarId: 928397,
  },
  {
    id: 10,
    name: "Enteri Redack",
    email: "enteriredack@gmail.com",
    age: 42,
    phone: "(222)444-5555",
    address: "4123 Easer Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 533215,
  },
  {
    id: 11,
    name: "Steve Goodman",
    email: "stevegoodmane@gmail.com",
    age: 11,
    phone: "(444)555-6239",
    address: "51234 Fiveton Street, CunFory, ND 212412",
    city: "Colunza",
    zipCode: "1234",
    registrarId: 92197,
  },
];

export const mockDataFinetunes = [
  {
    id: "sales-llama",
    createdAt: "2023-09-10 17:00:00",
    baseModel: "llama2-7b",
    datasetSize: "3942",
    status: "DEPLOYED",
  },
  {
    id: "translator",
    createdAt: "2023-08-15 10:00:00",
    baseModel: "llama2-13b",
    datasetSize: "1204",
    status: "DEPLOYED",
  },
  {
    id: "analytics-checkpoint",
    createdAt: "2023-08-18 09:00:00",
    baseModel: "llama2-13b",
    datasetSize: "2790",
    status: "DEPLOYED",
  },
  {
    id: "health-llama",
    createdAt: "2023-09-02 13:00:00",
    baseModel: "llama2-70b",
    datasetSize: "6920",
    status: "PENDING",
  },
]
export const mockDataChatExperiments = [
  {
    "id": 1,
    "question": "What is Few-shot?",
    "label": "Few-shot learning presents a set of high-quality demonstrations, each consisting of both input and desired output, on the target task. As the model first sees good examples, it can better understand human intention and criteria for what kinds of answers are wanted. ", 
    "ebrOutput": "Few-shot learning presents a set of high-quality demonstrations, each consisting of both input and desired output, on the target task. As the model first sees good examples, it can better understand human intention and criteria for what kinds of answers are wanted. ",
    "ragOutput": "Few-shot learning is a technique in which a small set of high-quality, input-output demonstrations are provided on a target task for a model. This gives the model a better understanding of what kind of output is desired, helping it to outperform zero-shot learning. However, it requires more resource consumption and may not be suitable for longer input and output text.",
    "garOutput": "Few-shot learning is a technique that uses a small number of high-quality demonstrations, consisting of both input and output, to quickly train a model on a particular task. This approach is beneficial in terms of performance, but has a greater token consumption cost. Furthermore, long input and output texts may cause the context length limit to be exceeded."
  },
  {
    "id": 2,
    "question": "What is a Loss Function?",
    "label": "A loss function is a mathematical function used in machine learning to measure the difference between predicted values and actual values, helping to guide model training and optimization.",
    "ebrOutput": "A loss function is a mathematical function employed in machine learning to quantify the disparity between predicted values and true values. It plays a critical role in guiding model training and optimization.",
    "ragOutput": "A loss function is a mathematical function used in machine learning to assess the dissimilarity between predicted values and ground truth values. It serves as a pivotal factor in steering model training and optimization.",
    "garOutput": "A loss function is a mathematical function applied in machine learning to gauge the disparity between predicted values and actual values. It is instrumental in directing model training and optimization."
  },
  {
    "id": 3,
    "question": "What is the difference between Supervised Learning and Unsupervised Learning?",
    "label": "Supervised learning involves training a model on labeled data, while unsupervised learning deals with unlabelled data and focuses on discovering patterns and structure within the data.",
    "ebrOutput": "Supervised learning entails training a model on labeled data, where each input is associated with a corresponding target or label. Unsupervised learning, on the other hand, works with unlabeled data and is oriented towards identifying hidden patterns and data structure.",
    "ragOutput": "Supervised learning revolves around the use of labeled data for model training, where the model learns to map inputs to corresponding labels. Unsupervised learning, conversely, tackles unlabeled data to uncover inherent patterns and data structure.",
    "garOutput": "Supervised learning is characterized by training models with labeled data, where each data point is paired with a known target or label. Unsupervised learning, in contrast, operates with unlabeled data and focuses on the discovery of latent data patterns and structure."
  },
  {
    "id": 4,
    "question": "What is Active Learning?",
    "label": "Active learning is a machine learning approach that involves an algorithm selecting and labeling data points in an iterative process, often with human involvement, to improve model performance with minimal labeled data.",
    "ebrOutput": "Active learning is a machine learning strategy where an algorithm iteratively chooses and labels data points, frequently involving human input. This approach enhances model performance by learning from minimal labeled data.",
    "ragOutput": "Active learning is a machine learning technique in which an algorithm iteratively selects and labels data points, sometimes with human intervention. The goal is to boost model performance while minimizing the need for labeled data.",
    "garOutput": "Active learning is a machine learning methodology in which an algorithm iteratively identifies and labels data points, often with human participation. Its objective is to enhance model performance while requiring a minimal amount of labeled data."
  },
  {
    "id": 13,
    "question": "Explain the Attention Mechanism.",
    "label": "The Attention Mechanism is a component in deep learning models that allows the model to focus on specific parts of the input when making predictions or decisions.",
    "ebrOutput": "The Attention Mechanism is a vital component in deep learning models. It enables the model to concentrate on specific portions of the input data when making predictions or decisions, enhancing its performance.",
    "ragOutput": "The Attention Mechanism is a crucial element in deep learning models. It empowers the model to direct its focus to particular segments of the input data when making predictions or decisions, leading to more accurate results.",
    "garOutput": "The Attention Mechanism is a fundamental component in deep learning models. It grants the model the ability to prioritize and focus on specific sections of the input data when making predictions or decisions, resulting in improved accuracy."
  },
  {
    "id": 16,
    "question": "What is the difference between Precision and Recall?",
    "label": "Precision measures the accuracy of positive predictions, while recall gauges the ability of a model to identify all relevant instances within a dataset.",
    "ebrOutput": "Precision assesses the precision of positive predictions made by a model, highlighting its ability to avoid false positives. Recall evaluates the model's capacity to capture all pertinent instances within a dataset.",
    "ragOutput": "Precision scrutinizes the precision of positive predictions produced by a model, emphasizing its capability to minimize false positives. Recall measures the model's effectiveness in capturing all relevant instances in a dataset.",
    "garOutput": "Precision focuses on the accuracy of positive predictions generated by a model, with an emphasis on minimizing false positives. Recall, on the other hand, evaluates the model's ability to identify all relevant instances within a dataset."
  },
]

export const mockDataInvoices = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    cost: "21.24",
    phone: "(665)121-5454",
    date: "03/12/2022",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    cost: "1.24",
    phone: "(421)314-2288",
    date: "06/15/2021",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    cost: "11.24",
    phone: "(422)982-6739",
    date: "05/02/2022",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    cost: "80.55",
    phone: "(921)425-6742",
    date: "03/21/2022",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    cost: "1.24",
    phone: "(421)445-1189",
    date: "01/12/2021",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    cost: "63.12",
    phone: "(232)545-6483",
    date: "11/02/2022",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    cost: "52.42",
    phone: "(543)124-0123",
    date: "02/11/2022",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    cost: "21.24",
    phone: "(222)444-5555",
    date: "05/02/2021",
  },
];

// export const mockBillings = [
//   {
//     txId: "01e4dsa",
//     user: "johndoe",
//     date: "2021-09-01",
//     cost: "43.95",
//   },
// ]
export const mockTransactions = [
  {
    txId: "01e4dsa",
    user: "johndoe",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "51034szv",
    user: "goodmanave",
    date: "2022-11-05",
    cost: "200.95",
  },
  {
    txId: "0a123sb",
    user: "stevebower",
    date: "2022-11-02",
    cost: "13.55",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "120s51a",
    user: "wootzifer",
    date: "2019-04-15",
    cost: "24.20",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
];

export const mockBarData = [
  {
    country: "AD",
    "text": 137,
    "textColor": "hsl(229, 70%, 50%)",
    stream: 96,
    streamColor: "hsl(296, 70%, 50%)",
    image: 72,
    imageColor: "hsl(97, 70%, 50%)",
    embedding: 140,
    embeddingColor: "hsl(340, 70%, 50%)",
  },
  {
    country: "AE",
    "text": 55,
    "textColor": "hsl(307, 70%, 50%)",
    stream: 28,
    streamColor: "hsl(111, 70%, 50%)",
    image: 58,
    imageColor: "hsl(273, 70%, 50%)",
    embedding: 29,
    embeddingColor: "hsl(275, 70%, 50%)",
  },
  {
    country: "AF",
    "text": 109,
    "textColor": "hsl(72, 70%, 50%)",
    stream: 23,
    streamColor: "hsl(96, 70%, 50%)",
    image: 34,
    imageColor: "hsl(106, 70%, 50%)",
    embedding: 152,
    embeddingColor: "hsl(256, 70%, 50%)",
  },
  {
    country: "AG",
    "text": 133,
    "textColor": "hsl(257, 70%, 50%)",
    stream: 52,
    streamColor: "hsl(326, 70%, 50%)",
    image: 43,
    imageColor: "hsl(110, 70%, 50%)",
    embedding: 83,
    embeddingColor: "hsl(9, 70%, 50%)",
  },
  {
    country: "AI",
    "text": 81,
    "textColor": "hsl(190, 70%, 50%)",
    stream: 80,
    streamColor: "hsl(325, 70%, 50%)",
    image: 112,
    imageColor: "hsl(54, 70%, 50%)",
    embedding: 35,
    embeddingColor: "hsl(285, 70%, 50%)",
  },
  {
    country: "AL",
    "text": 66,
    "textColor": "hsl(208, 70%, 50%)",
    stream: 111,
    streamColor: "hsl(334, 70%, 50%)",
    image: 167,
    imageColor: "hsl(182, 70%, 50%)",
    embedding: 18,
    embeddingColor: "hsl(76, 70%, 50%)",
  },
  {
    country: "AM",
    "text": 80,
    "textColor": "hsl(87, 70%, 50%)",
    stream: 47,
    streamColor: "hsl(141, 70%, 50%)",
    image: 158,
    imageColor: "hsl(224, 70%, 50%)",
    embedding: 49,
    embeddingColor: "hsl(274, 70%, 50%)",
  },
];

export const mockPieData = [
  {
    id: "hack",
    label: "hack",
    value: 239,
    color: "hsl(104, 70%, 50%)",
  },
  {
    id: "make",
    label: "make",
    value: 170,
    color: "hsl(162, 70%, 50%)",
  },
  {
    id: "go",
    label: "go",
    value: 322,
    color: "hsl(291, 70%, 50%)",
  },
  {
    id: "lisp",
    label: "lisp",
    value: 503,
    color: "hsl(229, 70%, 50%)",
  },
  {
    id: "scala",
    label: "scala",
    value: 584,
    color: "hsl(344, 70%, 50%)",
  },
];

export const mockLineDataHour = [
  {
    id: "token",
    color: tokens("dark").greenAccent[500],
    data: [
      {
          x: "2023-09-10 10:00:00",
          y: 355
      },
      {
          x: "2023-09-10 11:00:00",
          y: 931
      },
      {
          x: "2023-09-10 12:00:00",
          y: 773
      },
      {
          x: "2023-09-10 13:00:00",
          y: 901
      },
      {
          x: "2023-09-10 14:00:00",
          y: 644
      },
      {
          x: "2023-09-10 15:00:00",
          y: 216
      },
      {
          x: "2023-09-10 16:00:00",
          y: 393
      },
      {
          x: "2023-09-10 17:00:00",
          y: 398
      },
      {
          x: "2023-09-10 18:00:00",
          y: 590
      },
      {
          x: "2023-09-10 19:00:00",
          y: 815
      },
      {
          x: "2023-09-10 20:00:00",
          y: 677
      },
      {
          x: "2023-09-10 21:00:00",
          y: 478
      },
      {
          x: "2023-09-10 22:00:00",
          y: 475
      },
      {
          x: "2023-09-10 23:00:00",
          y: 179
      },
      {
          x: "2023-09-11 00:00:00",
          y: 728
      },
      {
          x: "2023-09-11 01:00:00",
          y: 361
      },
      {
          x: "2023-09-11 02:00:00",
          y: 275
      },
      {
          x: "2023-09-11 03:00:00",
          y: 155
      },
      {
          x: "2023-09-11 04:00:00",
          y: 691
      },
      {
          x: "2023-09-11 05:00:00",
          y: 437
      },
      {
          x: "2023-09-11 06:00:00",
          y: 352
      },
      {
          x: "2023-09-11 07:00:00",
          y: 282
      },
      {
          x: "2023-09-11 08:00:00",
          y: 890
      },
      {
          x: "2023-09-11 09:00:00",
          y: 791
      }
    ]
  }
]
export const mockLineData = [
  {
    id: "token",
    color: tokens("dark").greenAccent[500],
    data: [
      {
        x: "2023-09-01",
        y: 765
      },
      {
        x: "2023-09-02",
        y: 532
      },
      {
        x: "2023-09-03",
        y: 610
      },
      {
        x: "2023-09-04",
        y: 998
      },
      {
        x: "2023-09-05",
        y: 1230
      },
      {
        x: "2023-09-06",
        y: 1683
      },
      {
        x: "2023-09-07",
        y: 1320
      },
      {
        x: "2023-09-08",
        y: 1356
      }
  //     {
  //     x: "2023-09-10 10:00:00",
  //     y: 355
  // },
  // {
  //     x: "2023-09-10 11:00:00",
  //     y: 931
  // },
  // {
  //     x: "2023-09-10 12:00:00",
  //     y: 773
  // },
  // {
  //     x: "2023-09-10 13:00:00",
  //     y: 901
  // },
  // {
  //     x: "2023-09-10 14:00:00",
  //     y: 644
  // },
  // {
  //     x: "2023-09-10 15:00:00",
  //     y: 216
  // },
  // {
  //     x: "2023-09-10 16:00:00",
  //     y: 393
  // },
  // {
  //     x: "2023-09-10 17:00:00",
  //     y: 398
  // },
  // {
  //     x: "2023-09-10 18:00:00",
  //     y: 590
  // },
  // {
  //     x: "2023-09-10 19:00:00",
  //     y: 815
  // },
  // {
  //     x: "2023-09-10 20:00:00",
  //     y: 677
  // },
  // {
  //     x: "2023-09-10 21:00:00",
  //     y: 478
  // },
  // {
  //     x: "2023-09-10 22:00:00",
  //     y: 475
  // },
  // {
  //     x: "2023-09-10 23:00:00",
  //     y: 179
  // },
  // {
  //     x: "2023-09-11 00:00:00",
  //     y: 728
  // },
  // {
  //     x: "2023-09-11 01:00:00",
  //     y: 361
  // },
  // {
  //     x: "2023-09-11 02:00:00",
  //     y: 275
  // },
  // {
  //     x: "2023-09-11 03:00:00",
  //     y: 155
  // },
  // {
  //     x: "2023-09-11 04:00:00",
  //     y: 691
  // },
  // {
  //     x: "2023-09-11 05:00:00",
  //     y: 437
  // },
  // {
  //     x: "2023-09-11 06:00:00",
  //     y: 352
  // },
  // {
  //     x: "2023-09-11 07:00:00",
  //     y: 282
  // },
  // {
  //     x: "2023-09-11 08:00:00",
  //     y: 890
  // },
  // {
  //     x: "2023-09-11 09:00:00",
  //     y: 791
  // }
]
  }
  // {
  //   id: "token",
  //   color: tokens("dark").greenAccent[500],
  //   data: [
  //     {
  //       x: "plane",
  //       y: 101,
  //     },
  //     {
  //       x: "helicopter",
  //       y: 75,
  //     },
  //     {
  //       x: "boat",
  //       y: 36,
  //     },
  //     {
  //       x: "train",
  //       y: 216,
  //     },
  //     {
  //       x: "subway",
  //       y: 35,
  //     },
  //     {
  //       x: "bus",
  //       y: 236,
  //     },
  //     {
  //       x: "car",
  //       y: 88,
  //     },
  //     {
  //       x: "moto",
  //       y: 232,
  //     },
  //     {
  //       x: "bicycle",
  //       y: 281,
  //     },
  //     {
  //       x: "horse",
  //       y: 1,
  //     },
  //     {
  //       x: "skateboard",
  //       y: 35,
  //     },
  //     {
  //       x: "others",
  //       y: 14,
  //     },
  //   ],
  // },
  // {
  //   id: "france",
  //   color: tokens("dark").blueAccent[300],
  //   data: [
  //     {
  //       x: "plane",
  //       y: 212,
  //     },
  //     {
  //       x: "helicopter",
  //       y: 190,
  //     },
  //     {
  //       x: "boat",
  //       y: 270,
  //     },
  //     {
  //       x: "train",
  //       y: 9,
  //     },
  //     {
  //       x: "subway",
  //       y: 75,
  //     },
  //     {
  //       x: "bus",
  //       y: 175,
  //     },
  //     {
  //       x: "car",
  //       y: 33,
  //     },
  //     {
  //       x: "moto",
  //       y: 189,
  //     },
  //     {
  //       x: "bicycle",
  //       y: 97,
  //     },
  //     {
  //       x: "horse",
  //       y: 87,
  //     },
  //     {
  //       x: "skateboard",
  //       y: 299,
  //     },
  //     {
  //       x: "others",
  //       y: 251,
  //     },
  //   ],
  // },
  // {
  //   id: "us",
  //   color: tokens("dark").redAccent[200],
  //   data: [
  //     {
  //       x: "plane",
  //       y: 191,
  //     },
  //     {
  //       x: "helicopter",
  //       y: 136,
  //     },
  //     {
  //       x: "boat",
  //       y: 91,
  //     },
  //     {
  //       x: "train",
  //       y: 190,
  //     },
  //     {
  //       x: "subway",
  //       y: 211,
  //     },
  //     {
  //       x: "bus",
  //       y: 152,
  //     },
  //     {
  //       x: "car",
  //       y: 189,
  //     },
  //     {
  //       x: "moto",
  //       y: 152,
  //     },
  //     {
  //       x: "bicycle",
  //       y: 8,
  //     },
  //     {
  //       x: "horse",
  //       y: 197,
  //     },
  //     {
  //       x: "skateboard",
  //       y: 107,
  //     },
  //     {
  //       x: "others",
  //       y: 170,
  //     },
  //   ],
  // },
];

export const mockGeographyData = [
  {
    id: "AFG",
    value: 520600,
  },
  {
    id: "AGO",
    value: 949905,
  },
  {
    id: "ALB",
    value: 329910,
  },
  {
    id: "ARE",
    value: 675484,
  },
  {
    id: "ARG",
    value: 432239,
  },
  {
    id: "ARM",
    value: 288305,
  },
  {
    id: "ATA",
    value: 415648,
  },
  {
    id: "ATF",
    value: 665159,
  },
  {
    id: "AUT",
    value: 798526,
  },
  {
    id: "AZE",
    value: 481678,
  },
  {
    id: "BDI",
    value: 496457,
  },
  {
    id: "BEL",
    value: 252276,
  },
  {
    id: "BEN",
    value: 440315,
  },
  {
    id: "BFA",
    value: 343752,
  },
  {
    id: "BGD",
    value: 920203,
  },
  {
    id: "BGR",
    value: 261196,
  },
  {
    id: "BHS",
    value: 421551,
  },
  {
    id: "BIH",
    value: 974745,
  },
  {
    id: "BLR",
    value: 349288,
  },
  {
    id: "BLZ",
    value: 305983,
  },
  {
    id: "BOL",
    value: 430840,
  },
  {
    id: "BRN",
    value: 345666,
  },
  {
    id: "BTN",
    value: 649678,
  },
  {
    id: "BWA",
    value: 319392,
  },
  {
    id: "CAF",
    value: 722549,
  },
  {
    id: "CAN",
    value: 332843,
  },
  {
    id: "CHE",
    value: 122159,
  },
  {
    id: "CHL",
    value: 811736,
  },
  {
    id: "CHN",
    value: 593604,
  },
  {
    id: "CIV",
    value: 143219,
  },
  {
    id: "CMR",
    value: 630627,
  },
  {
    id: "COG",
    value: 498556,
  },
  {
    id: "COL",
    value: 660527,
  },
  {
    id: "CRI",
    value: 60262,
  },
  {
    id: "CUB",
    value: 177870,
  },
  {
    id: "-99",
    value: 463208,
  },
  {
    id: "CYP",
    value: 945909,
  },
  {
    id: "CZE",
    value: 500109,
  },
  {
    id: "DEU",
    value: 63345,
  },
  {
    id: "DJI",
    value: 634523,
  },
  {
    id: "DNK",
    value: 731068,
  },
  {
    id: "DOM",
    value: 262538,
  },
  {
    id: "DZA",
    value: 760695,
  },
  {
    id: "ECU",
    value: 301263,
  },
  {
    id: "EGY",
    value: 148475,
  },
  {
    id: "ERI",
    value: 939504,
  },
  {
    id: "ESP",
    value: 706050,
  },
  {
    id: "EST",
    value: 977015,
  },
  {
    id: "ETH",
    value: 461734,
  },
  {
    id: "FIN",
    value: 22800,
  },
  {
    id: "FJI",
    value: 18985,
  },
  {
    id: "FLK",
    value: 64986,
  },
  {
    id: "FRA",
    value: 447457,
  },
  {
    id: "GAB",
    value: 669675,
  },
  {
    id: "GBR",
    value: 757120,
  },
  {
    id: "GEO",
    value: 158702,
  },
  {
    id: "GHA",
    value: 893180,
  },
  {
    id: "GIN",
    value: 877288,
  },
  {
    id: "GMB",
    value: 724530,
  },
  {
    id: "GNB",
    value: 387753,
  },
  {
    id: "GNQ",
    value: 706118,
  },
  {
    id: "GRC",
    value: 377796,
  },
  {
    id: "GTM",
    value: 66890,
  },
  {
    id: "GUY",
    value: 719300,
  },
  {
    id: "HND",
    value: 739590,
  },
  {
    id: "HRV",
    value: 929467,
  },
  {
    id: "HTI",
    value: 538961,
  },
  {
    id: "HUN",
    value: 146095,
  },
  {
    id: "IDN",
    value: 490681,
  },
  {
    id: "IND",
    value: 549818,
  },
  {
    id: "IRL",
    value: 630163,
  },
  {
    id: "IRN",
    value: 596921,
  },
  {
    id: "IRQ",
    value: 767023,
  },
  {
    id: "ISL",
    value: 478682,
  },
  {
    id: "ISR",
    value: 963688,
  },
  {
    id: "ITA",
    value: 393089,
  },
  {
    id: "JAM",
    value: 83173,
  },
  {
    id: "JOR",
    value: 52005,
  },
  {
    id: "JPN",
    value: 199174,
  },
  {
    id: "KAZ",
    value: 181424,
  },
  {
    id: "KEN",
    value: 60946,
  },
  {
    id: "KGZ",
    value: 432478,
  },
  {
    id: "KHM",
    value: 254461,
  },
  {
    id: "OSA",
    value: 942447,
  },
  {
    id: "KWT",
    value: 414413,
  },
  {
    id: "LAO",
    value: 448339,
  },
  {
    id: "LBN",
    value: 620090,
  },
  {
    id: "LBR",
    value: 435950,
  },
  {
    id: "LBY",
    value: 75091,
  },
  {
    id: "LKA",
    value: 595124,
  },
  {
    id: "LSO",
    value: 483524,
  },
  {
    id: "LTU",
    value: 867357,
  },
  {
    id: "LUX",
    value: 689172,
  },
  {
    id: "LVA",
    value: 742980,
  },
  {
    id: "MAR",
    value: 236538,
  },
  {
    id: "MDA",
    value: 926836,
  },
  {
    id: "MDG",
    value: 840840,
  },
  {
    id: "MEX",
    value: 353910,
  },
  {
    id: "MKD",
    value: 505842,
  },
  {
    id: "MLI",
    value: 286082,
  },
  {
    id: "MMR",
    value: 915544,
  },
  {
    id: "MNE",
    value: 609500,
  },
  {
    id: "MNG",
    value: 410428,
  },
  {
    id: "MOZ",
    value: 32868,
  },
  {
    id: "MRT",
    value: 375671,
  },
  {
    id: "MWI",
    value: 591935,
  },
  {
    id: "MYS",
    value: 991644,
  },
  {
    id: "NAM",
    value: 701897,
  },
  {
    id: "NCL",
    value: 144098,
  },
  {
    id: "NER",
    value: 312944,
  },
  {
    id: "NGA",
    value: 862877,
  },
  {
    id: "NIC",
    value: 90831,
  },
  {
    id: "NLD",
    value: 281879,
  },
  {
    id: "NOR",
    value: 224537,
  },
  {
    id: "NPL",
    value: 322331,
  },
  {
    id: "NZL",
    value: 86615,
  },
  {
    id: "OMN",
    value: 707881,
  },
  {
    id: "PAK",
    value: 158577,
  },
  {
    id: "PAN",
    value: 738579,
  },
  {
    id: "PER",
    value: 248751,
  },
  {
    id: "PHL",
    value: 557292,
  },
  {
    id: "PNG",
    value: 516874,
  },
  {
    id: "POL",
    value: 682137,
  },
  {
    id: "PRI",
    value: 957399,
  },
  {
    id: "PRT",
    value: 846430,
  },
  {
    id: "PRY",
    value: 720555,
  },
  {
    id: "QAT",
    value: 478726,
  },
  {
    id: "ROU",
    value: 259318,
  },
  {
    id: "RUS",
    value: 268735,
  },
  {
    id: "RWA",
    value: 136781,
  },
  {
    id: "ESH",
    value: 151957,
  },
  {
    id: "SAU",
    value: 111821,
  },
  {
    id: "SDN",
    value: 927112,
  },
  {
    id: "SDS",
    value: 966473,
  },
  {
    id: "SEN",
    value: 158085,
  },
  {
    id: "SLB",
    value: 178389,
  },
  {
    id: "SLE",
    value: 528433,
  },
  {
    id: "SLV",
    value: 353467,
  },
  {
    id: "ABV",
    value: 251,
  },
  {
    id: "SOM",
    value: 445243,
  },
  {
    id: "SRB",
    value: 202402,
  },
  {
    id: "SUR",
    value: 972121,
  },
  {
    id: "SVK",
    value: 319923,
  },
  {
    id: "SVN",
    value: 728766,
  },
  {
    id: "SWZ",
    value: 379669,
  },
  {
    id: "SYR",
    value: 16221,
  },
  {
    id: "TCD",
    value: 101273,
  },
  {
    id: "TGO",
    value: 498411,
  },
  {
    id: "THA",
    value: 506906,
  },
  {
    id: "TJK",
    value: 613093,
  },
  {
    id: "TKM",
    value: 327016,
  },
  {
    id: "TLS",
    value: 607972,
  },
  {
    id: "TTO",
    value: 936365,
  },
  {
    id: "TUN",
    value: 898416,
  },
  {
    id: "TUR",
    value: 237783,
  },
  {
    id: "TWN",
    value: 878213,
  },
  {
    id: "TZA",
    value: 442174,
  },
  {
    id: "UGA",
    value: 720710,
  },
  {
    id: "UKR",
    value: 74172,
  },
  {
    id: "URY",
    value: 753177,
  },
  {
    id: "USA",
    value: 658725,
  },
  {
    id: "UZB",
    value: 550313,
  },
  {
    id: "VEN",
    value: 707492,
  },
  {
    id: "VNM",
    value: 538907,
  },
  {
    id: "VUT",
    value: 650646,
  },
  {
    id: "PSE",
    value: 476078,
  },
  {
    id: "YEM",
    value: 957751,
  },
  {
    id: "ZAF",
    value: 836949,
  },
  {
    id: "ZMB",
    value: 714503,
  },
  {
    id: "ZWE",
    value: 405217,
  },
  {
    id: "KOR",
    value: 171135,
  },
];
