import React, {useState, useContext} from "react";
// import {Navigate, Link} from "react-router-dom";
// import {AuthContext} from "../AuthContext"
import Labs from "./labs";
import LandingHeader from "./components/landing/landingHeader"
import './css/main.css';
// import api from "./api";
import axios from "axios";
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import {MdSecurity} from "react-icons/md"
import { FaDiscord } from "react-icons/fa"
import {FaGithub} from "react-icons/fa"
import {FaTwitter, FaBrain, FaCogs, FaProjectDiagram, FaConnectdevelop, FaNetworkWired } from "react-icons/fa"
import {FiSearch, FiDatabase, FiShare2} from "react-icons/fi"


// import {AiFillYoutube} from "react-icons/ai"
// import { typeFromAST } from "graphql";

function Landing() {
  // Uncomment and configure these lines if you're using authentication
  // const { authenticated } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [joined, setJoined] = useState(false);

  // Handler for when a user joins the waitlist
  const handleJoinWaitlist = () => {
    // Implement your logic for joining the waitlist
    setJoined(true);
  };

  return (
    // Redirect if authenticated, replace with your logic
    // authenticated() ? <Navigate to="/dashboard" /> :


<div>
    <div className="relative overflow-hidden">
      <main>
        <div className="gradient-orange lg:pb-14 min-h-screen">
          <LandingHeader />
          
          {/* <!-- Headlines container --> */}
          <div className="mx-auto max-w-7xl px-4 text-center" style={{ paddingTop: '10vh', paddingBottom: '5vh' }}> {/* Adjusted padding for spacing */}
            <h1 className="text-6xl tracking-tight font-bold text-orange-500 sm:text-6xl xl:text-6xl">
              <span className="blocktext-orange-500">Large Language Models </span>
              <span className="text-white">Meet with </span>
              <span className="block text-orange-500">Knowledge Graphs</span>
            </h1>
            <p className="mt-3 text-3xl font-medium text-grey-400 sm:mt-8">
            Domain-Specific Search Solutions using LLMs and KGs.
            </p>     
            <div className="mt-7 button-container">
              <a className="cursor-pointer py-4 px-7 rounded-lg shadow font-bold bg-orange-500 text-white text-lg font-semibold hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900" href="https://getwaitlist.com/waitlist/13018" target="_blank">Join Waitlist</a>               
            </div>                             
          </div>
          
          {/* <!-- Flowchart container --> */}
          <div className="flex justify-center items-center px-4 pb-4"> {/* Reduced bottom margin/padding */}
            <img style={{height: "40rem"}} className="rounded-xl" src={`../../assets/tigerbytes-flowchart.png`} alt=""/> 
          </div>
        </div>
      </main>
    </div>

    <div class="relative bg-white py-16 sm:py-16 lg:py-20"></div>
    <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">      
      <p class="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl">
      Developer toolkit easily integrated into your products.
      </p>
      {/* <p class="mt-5 max-w-prose mx-auto text-2xl text-gray-500">
      Developer Toolkit Easily Integrated into Your Products
      </p> */}
        <div class="mt-12">
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          
          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                <span class="inline-flex items-center justify-center p-3 text-white text-xl bg-orange-400 rounded-md shadow-lg">
                  {/* <span class="inline-flex items-center justify-center p-3 bg-orange-400 rounded-md shadow-lg"> */}
                    {/* <!-- Heroicon name: outline/refresh --> */}
                    {/* <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>    */}
                    <FaProjectDiagram/>                    
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight font-sans">TigerGraph</h3>
                <p class="mt-5 text-xl text-gray-500">
                Graph Data Solutions
                </p>
              </div>
            </div>
          </div>

          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 text-white text-xl bg-orange-400 rounded-md shadow-lg">
                  <FaBrain/>
                    {/* <!-- Heroicon name: outline/server --> */}
                    {/* <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
                    </svg> */}
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight font-sans">TigerRAG</h3>
                <p class="mt-5 text-xl text-gray-500">
                Best of Both Worlds of LLM and KG
                </p>
              </div>
            </div>
          </div>

          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 text-white text-xl bg-orange-400 rounded-md shadow-lg">
                    {/* <!-- Heroicon name: outline/cog --> */}
                    {/* <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg> */}
                    {/* <FaCogs/> */}
                    <FaNetworkWired/>
                    {/* <FaConnectdevelop/>    */}

                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight font-sans">TigerModel</h3>
                <p class="mt-5 text-xl text-gray-500">
                  LLM Model Solutions
                </p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>

    <div class="relative bg-white py-16 sm:py-16 lg:py-20">
    <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
    <p class="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl">
        Safety and trustworthy are our highest priority.
      </p>

      {/* <!-- Bullet points container with larger font and two rows --> */}
      <div class="mt-10 flex justify-center flex-wrap">
        <div class="w-full md:w-1/2 px-4 py-2">
          <p class="text-lg md:text-2xl font-medium text-gray-900 flex items-center justify-center">
          🛡️ Safe Guard the Whole Pipeline
          </p>
        </div>
        <div class="w-full md:w-1/2 px-4 py-2">
          <p class="text-lg md:text-2xl font-medium text-gray-900 flex items-center justify-center">
          🔒 Enhanced Data Privacy
          </p>
        </div>
        <div class="w-full md:w-1/2 px-4 py-2">
          <p class="text-lg md:text-2xl font-medium text-gray-900 flex items-center justify-center">
          ✅ Factualness Checking          
          </p>
        </div>
        <div class="w-full md:w-1/2 px-4 py-2">
          <p class="text-lg md:text-2xl font-medium text-gray-900 flex items-center justify-center">
            👥 Crowd Sourced Safety Datasets
          </p>
        </div>
      </div>
    </div>

    <div class="relative bg-white py-16 sm:py-16 lg:py-20">
    <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <p class="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl">
      Provide services to both consumers and enterprises.
      </p>
      <div class="flex justify-center space-x-4 mt-8">
        <img style={{height: "20rem"}} className="rounded-xl" src={`../../assets/customer-1.png`} alt=""/> 
        <img style={{height: "20rem"}} className="rounded-xl" src={`../../assets/customer-2.png`} alt=""/>         
      </div>
      </div>
    </div>
   

</div>
  
{/* <!-- Features --> */}
{/* <!-- This example requires Tailwind CSS v2.0+ --> */}

  <footer class="bg-black" aria-labelledby="footerHeading">
    <h2 id="footerHeading" class="sr-only">Footer</h2>
    <div class="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div class="xl:grid xl:grid-cols-4 xl:gap-8 mb-16">
            <div>
              <h3 class="font-semibold text-orange-500 tracking-wider uppercase font-sans">
                Products
              </h3>
              <ul class="mt-4 space-y-4 p-0">
                <li> 
                  <a href="https://getwaitlist.com/waitlist/13018" target="_blank" class="text-xl text-white hover:text-orange-500 hover:no-underline">
                    Beta Waitlist
                  </a>
                </li>                
              
                <li> 
                  <a href="" target="_blank" class="text-xl text-white hover:text-orange-500 hover:no-underline">
                    Labs
                  </a>
                </li>
              </ul>
            </div>
            
            <div>
              <h3 class="font-semibold text-orange-500 tracking-wider uppercase font-sans">
                Use Cases
              </h3>
              <ul class="mt-4 space-y-4 p-0">                
                <li>
                  <a href="" class="text-xl text-white hover:text-orange-500 hover:no-underline">
                    Law & Education
                  </a>
                </li>
                <li>
                  <a href="" target="_blank" class="text-xl text-white hover:text-orange-500 hover:no-underline">
                  Research & Academics
                  </a>
                </li>
                <li>
                  <a href="" class="text-xl text-white hover:text-orange-500 hover:no-underline">
                    E-commerce Product Search
                  </a>
                </li>            
                <li>
                  <a href="" class="text-xl text-white hover:text-orange-500 hover:no-underline">
                    Movie/Place/Restaurant Recommendations
                  </a>
                </li>
              </ul>
            </div>          

            <div>
              <h3 class="font-semibold text-orange-500 tracking-wider uppercase font-sans">
                Contact US
              </h3>
              <ul class="mt-4 space-y-4 p-0">
                <li>
                  <a class="text-xl text-white hover:text-orange-500 hover:no-underline">
                    founders@tigerbytes.ai
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h3 class="font-semibold text-orange-500 tracking-wider uppercase font-sans">
                About Us
              </h3>
              <ul class="mt-4 space-y-4 p-0">
                <li>
                  <a href="" target="_blank" class="text-xl text-white hover:text-orange-500 hover:no-underline">
                    Team
                  </a>
                </li>
                <li>
                  <a href="" target="_blank" class="text-xl text-white hover:text-orange-500 hover:no-underline">
                    Blogs
                  </a>
                </li>
              </ul>
            </div>
            
      </div>
      <div class="flex items-center justify-between border-t border-gray-200">
          <div class="mt-8 ">
            <div class="flex flex-row items-center justify-between">
                <img
                    style={{height: "3rem"}}
                    src={`../../tigers.png`}
                    alt=""
                />
                <h
                  class="block px-3 text-2xl whitespace-nowrap w-64 font-semibold text-orange-500 "
                >
                  {"TigerBytes AI"}
                </h>
            </div>
            <p class="mt-2 text-md text-gray-400">
              &copy; 2024 TigerBytes AI. 
            </p>
          </div>
          <div class="mt-8 flex">
            <a href="https://github.com/tigerbytes-ai/tigers" target="_blank" class="text-white mr-2 hover:text-gray-500 text-2xl">
              <FaGithub/>
            </a>
            <a href="https://twitter.com/TigerBytesAI" target="_blank" class="text-white hover:text-gray-500 text-2xl">
              <FaTwitter/>
            </a>
            {/* <a href="https://discord.gg/GnwH2STv" target="_blank" class="pl-1 text-white hover:text-gray-500 text-2xl">
              <FaDiscord />
            </a> */}
          </div>          
      </div>
    </div>
  </footer>
  

  </div>  
      
    );

}

{/* export default Landing; */}

// import React, { useState } from "react";
// import LandingHeader from "./components/landing/landingHeader";
// import axios from "axios";
// import { MdSecurity } from "react-icons/md";
// import { FaDiscord, FaGithub, FaTwitter } from "react-icons/fa";
// import { FiSearch } from "react-icons/fi";

// function Landing() {
//   const [username, setUsername] = useState("");
//   const [joined, setJoined] = useState(false);

//   const joinWaitlist = async (e) => {
//     e.preventDefault();
//     setJoined(true);
//     try {
//       const response = await axios.post('https://forms.gle/mAijScK89MgiyLGY8', { email: username });
//       console.log(response);
//       setJoined(true);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <div>
//       <div className="relative overflow-hidden">
//         <main>
//           <div className="bg-gradient-to-b from-teal-500 to-teal-700 lg:pb-14 h-screen">
//             <LandingHeader />

//             <div className="mx-auto max-w-7xl lg:pl-4 h-full flex items-center pb-4">
//               <div className="lg:grid lg:grid-cols-3 lg:gap-8">
//                 <div className="col-span-2 mx-auto max-w-md px-2 sm:max-w-2xl sm:px-2 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
//                   <div className="lg:py-24">
//                     <h1 className="2xl:mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-6 sm:text-6xl xl:text-6xl text-left">
//                       <span className="block">TigerBytes: </span>
//                       <span className="block">Enhanced Search Capabilities using LLMs</span>
//                     </h1>
//                     <p className="mt-3 text-xl font-medium text-teal-200 sm:mt-8 sm:text-xl lg:text-xl xl:text-xl">
//                       Trustworthy Search Solutions Customized for Your Own Data
//                     </p>
//                     {/* Other content here */}
//                   </div>
//                 </div>
//                 <div className="col-span-1 mt-6 2xl:mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
//                   {/* Image or content here */}
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* More main page content here... */}
//         </main>
//       </div>

//       {/* Features Section */}
//       <div className="relative bg-white py-16 sm:py-16 lg:py-20">
//         {/* Content here */}
//       </div>

//       {/* Footer */}
//       <footer className="bg-teal-900" aria-labelledby="footerHeading">
//         {/* Footer content here */}
//       </footer>
//     </div>
//   );
// }

export default Landing;
