import { height } from '@mui/system';
import React, { useState } from 'react';
// import '../../css/blog.css';
// import img_1 from "../assets/img_1.png";
// import img_2 from "../assets/img_2.png";
// import img_3 from "../assets/img_3.png";
// import img_4 from "../assets/img_4.png";
// import img_5 from "../assets/img_5.png";
// import img_6 from "../assets/img_6.png";

const BlogList = () => {
  const posts = [
    {
      id: 1,
      title: 'AI Safety Report: Llama 2 vs Mistral vs ChatGPT',
      href: '/blog/ai-safety-report',
      date: 'Nov 15, 2023',
      imageUrl:
          '../../../assets/ai-safety-illustration.png',
    },
    {
      id: 2,
      title: 'Introducing TigerLab AI: Open-Source LLM Toolkit',
      href: '/blog/open-source-toolkit',
      date: 'Nov 1, 2023',
      imageUrl:
          '../../../assets/github_square.png',
    },
    // More posts...
  ]

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto px-6 lg:px-20" style={{maxWidth: "56em"}}>
        <div className="mx-auto max-w-2xl lg:mx-0 flex justify-center">
          <h1 className=" font-bold text-gray-900 mt-10 not-italic" style={{fontSize: "4em"}}>Blog</h1>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16  pt-10 sm:mt-4 sm:pt-16 lg:max-w-none">
          {posts.map((post) => (
            <div key={post.id} className="flex max-w-xl flex-cols items-center mt-8">
              <a href={post.href}>
                <img src={post.imageUrl} alt="" class="bg-gray-50 object-contain border" style={{width: "9rem", height:"9rem", borderRadius: "20px"}}/>
              </a>
              <div style={{marginLeft:"40px"}} class="flex flex-col" >
                <a
                  href={post.href}
                  class="text-3xl font-semibold text-gray-800 leading-8"
                >
                  {post.title}
                </a>
                <a
                  href={post.href}
                  class="text-lg font-semibold text-gray-400 mt-3"
                >
                  {post.date}
                </a>

                {/* <time dateTime={post.datetime} className="text-gray-500">
                  {post.date}
                </time> */}
              </div>
              {/* <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
              </div> */}
              
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogList;
